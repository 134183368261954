import Banner from 'components/banner';
import ContentContact from 'components/contentContact/ContentContact';
import HeadContact from 'components/contentContact/HeadContact';
import Layout from 'components/layout';
import Seo from 'components/seo';
import React from 'react';

function contact() {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        {/* <HeadContact /> */}
        <Banner height="400px" title={'We are Skyline Tech'} content={"Let's talk about your store and how we can help"} />
        <ContentContact />
      </Layout>
    </>
  );
}

export default contact;
