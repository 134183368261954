import React, { useState,useRef } from 'react';
import emailjs from '@emailjs/browser';


const ContentContact = () => {
  const [input, setInput] = useState<any>({});
  const handleChange = (e:any) => setInput((prevState:any) => ({ ...prevState, [e.target.name]: e.target.value }));

  const handleSend = () => { 
      if((input.yourName &&input.yourName !=='') && (input.Email &&input.Email !=='') && (input.Message &&input.Message !=='') ){
        alert('Cảm ơn bạn đã gửi phản hồi đến chúng tôi')
        // setInput({}); 
      }
      else{
        alert('Yêu cầu nhập đầy đủ thông tin!')
      }
      
  };
  const sendEmail=(e:any)=>{
    e.preventDefault();
   console.log(e.target)
    emailjs.sendForm('service_cohwo2o', 'template_zbcpt7h', e.target, 'Rk_DznuC-wxnbSGfp')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }
  
  return (
    <div className="flex flex-col my-[80px]">
      <form   onSubmit={sendEmail}>
      <div className=" md:flex  justify-between mx-auto md:w-[918px] w-[325px] mb-[16px] ">
        <div className="md:w-[451px]  w-[325px] mx-auto md:mr-[16px]">
          <div className='flex'>
            <p className="text-[16px] my-[10px] font-bold text-gray-400   ">Your Name </p>
            <p className='text-[18px] my-[10px] text-red-500'>*</p>
          </div>
          <input
            className="md:w-[451px]  w-[325px] h-[50px] px-[20px] rounded-2xl border  hover:shadow"
            type="text"
            placeholder="Your Name"
            name="yourName"
            value={input.yourName || ''}
            onChange={handleChange}
          />
        </div>
        <div className="md:w-[451px]  w-[325px] mx-auto">
        <div className='flex'>
            <p className="text-[16px] my-[10px] font-bold text-gray-400   ">Email </p>
            <p className='text-[18px] my-[10px] text-red-500'>*</p>
          </div>
          <input
            className="md:w-[451px] w-[325px] h-[50px] px-[20px] rounded-2xl border  hover:shadow"
            type="email"
            placeholder="Email"
            name="Email"
            pattern=".+@gmail.com"
            value={input.Email || ''}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mx-auto md:w-[918px] w-[325px]  ">
        <div className='flex'>
            <p className="text-[16px] mb-[10px] font-bold text-gray-400   ">Message </p>
            <p className='text-[18px] text-red-500'>*</p>
          </div>
        <textarea
          className="md:w-[920px] w-[325px] h-[175px] px-[20px] rounded-2xl border py-2  hover:shadow"
          rows={6}
          cols={80}
          id=""
          placeholder="Message  to Skyline Tech..."
          name="Message"
          value={input.Message || ''}
          onChange={handleChange}>
          </textarea>
          <button
          className="w-[200px] h-[48px] bg-gradient-to-r from-cyan-500 to-teal-500 px-14 py-2 rounded-full text-white text-[16px] font-semibold mt-[16px] shadow hover:shadow-xl "
          onClick={() => handleSend()}>
          Send
        </button>
      </div>
      </form>
    </div>
  );
};

export default ContentContact;
